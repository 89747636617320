@import 'variables';

.icon-gray {
  .path1::before {
    color: #c7c7c7;
  }
  .path2::before {
    color: #c7c7c7;
  }
}

.icon-warn {
  .path1::before {
    color: #b30021;
  }
  .path2::before {
    color: #b30021;
  }
}

.icon__primary {
  &::before {
    color: $primary !important;
  }
}

.icon__textColor {
  &::before {
    color: $textColor !important;
  }
}

.icon__white {
  &::before {
    color: #ffffff !important;
  }
}

.icon__black::before {
  color: #000000 !important;
}

.icon__gray {
  &::before {
    color: #2a353c !important;
  }
}

.icon__menu {
  &::before {
    color: #62676b !important;
  }
}

.icon__gray-b2 {
  &::before {
    color: #939598 !important;
  }
}
.icon__gray-opacity {
  &::before {
    color: #2a353c80 !important;
  }
}

.icon__disabled {
  &::before {
    color: #c7c7c7 !important;
  }
}
