@import 'variables';

.custom-check {
  &.mat-mdc-checkbox {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background: #c7c7c7 !important;
          border-color: #c7c7c7 !important;
          border-radius: 0.25rem;
        }
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background: $primary !important;

          border-color: $primary !important;

          .mdc-checkbox__checkmark {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.mat-mdc-checkbox-ripple {
  display: none !important;
}

.mdc-checkbox__ripple {
  width: 0;
  height: 0;
}

.custom-void-checkbox {
  &.mat-mdc-checkbox {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          border-color: #c7c7c7 !important;
          border-radius: 0.25rem;
        }
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background: #16a34a !important;

          border-color: #16a34a !important;

          .mdc-checkbox__checkmark {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}
