@import 'variables';

.custom-icon-alert {
  .mat-mdc-icon-button[disabled][disabled] {
    --mdc-icon-button-icon-color: #000;
    --mdc-icon-button-disabled-icon-color: #000;
  }
}

.mat-datepicker-actions {
  padding: 0 !important;
}
