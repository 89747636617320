@import 'variables';

.mat-mdc-menu-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    height: 3rem;
    width: 100%;
    align-items: center;

    span {
      font-size: 18px;
      font-weight: 600;
    }

    .mat-18 {
      cursor: pointer;
    }
  }

  button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 3rem;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}

.color-block {
  .mat-mdc-menu-panel {
    overflow: hidden;
  }

  .block-card {
    background-color: #f6f6f6 !important;
    height: calc(14.5rem - 6rem);
    width: 13.5rem;
  }

  .block-body {
    padding: 1.5625rem 1.0625rem 1.0625rem 1.0625rem !important;

    .swatch {
      border-radius: 50% !important;
    }
  }

  .block-head {
    display: none !important;
  }

  .wrap {
    display: none !important;
  }
}

.btn-confirm {
  background-color: #d43f11 !important;
  color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

color-swatch:first-child {
  div:first-child {
    border: 2px solid red;

    &::before {
      content: '/';
      transform: translate(0, 0) rotate(-158deg);
      width: 20px;
      position: absolute;
      z-index: 99;
      top: -7px;
      right: 5px;
      font-size: 21px;
      color: red;
      font-weight: 600;
    }
  }
}
