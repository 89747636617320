@import 'variables';

.custom-stepper {
  .mat-step-header-ripple {
    display: none;
  }

  .mat-step-icon-state-edit {
    background-color: $gray-biz !important;
    color: #ffffff !important;
  }

  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background-color: transparent !important;
  }

  .mat-stepper-horizontal-line {
    display: none;
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused {
    background-color: transparent !important;
  }

  .mat-horizontal-stepper-header-container {
    display: flex;
    justify-content: space-between;

    .mat-step-header:nth-of-type(7) {
      display: none !important;
    }
  }

  .mat-step-header {
    pointer-events: none !important;
    // border-bottom: 2px solid #e8e8e8;
    width: 100%;
    // margin-block-end: 2.5rem;

    // &:nth-of-type(-n + 7) {
    //   display: none !important;
    // }
  }

  .mat-horizontal-content-container {
    padding-inline: 0 !important;
  }

  .mat-step-icon {
    background-color: transparent;
    color: #62676b;

    &.mat-step-icon-selected {
      background-color: $primary;
      color: #ffffff;
    }

    &.mat-step-icon-state-done {
      background-color: $gray-biz;
      color: #ffffff;
    }
  }

  .mat-step-label {
    &.mat-step-label-selected {
      color: $primary !important;
      font-weight: 600;
    }

    &.mat-step-label-active {
      color: $gray-biz;
    }
  }

  .mat-step-header.mat-step-completed {
    background-color: #4caf50; /* cor de fundo para passos completados */
    color: #fff; /* cor do texto para passos completados */
  }
}
