@import 'variables';

.custom-slide-toggle {
  border: 1px solid #e8e8e8;
  border-radius: 50px;
  padding-inline-start: 3px;
  padding-block-end: 3px;

  &.disabled {
    opacity: 0.6;
  }

  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
    background: #fff;
  }
  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::before {
    background: #fff;
  }

  .mdc-switch:disabled .mdc-switch__track::after {
    background: transparent !important;
  }
  .mdc-switch:disabled .mdc-switch__track::before {
    background: transparent !important;
  }

  .mdc-switch:enabled .mdc-switch__track::before {
    background: transparent !important;
  }

  &.default {
    &.mat-mdc-slide-toggle-checked {
      background-color: #16a34a !important;
    }
  }

  &.mat-mdc-slide-toggle-checked {
    background-color: $primary !important;
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: #c7c7c7 !important;
  }
}
