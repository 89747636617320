@import 'variables';

.pagination {
  ul.ngx-pagination {
    white-space: nowrap;
    margin: 0;
    li {
      border: 0.1rem solid transparent;
      min-width: 2rem;
      height: 2rem;
      text-align: center;
      border-radius: 0.25rem;
      &.current {
        background: transparent;
        color: $primary;
        border: 0.1rem solid $primary;
      }

      &.pagination-previous,
      &.pagination-next {
        background: $primary;

        @media (max-width: 768px) {
          width: 2rem;
        }
      }

      &.pagination-previous {
        margin-right: 1rem;

        &.disabled::before,
        > a::before {
          margin-left: -0.2rem;
          margin-bottom: 0.25rem;
          color: $textColor;
          content: '\2190';
          vertical-align: middle;

          @media (min-width: 768px) {
            content: '\2190  Anterior' !important;
          }
        }
      }

      &.pagination-next {
        margin-left: 1rem;

        &.disabled::after,
        > a::after {
          margin-left: -0.2rem;
          margin-bottom: 0.25rem;
          color: $textColor;
          content: '\2192';
          vertical-align: middle;

          @media (min-width: 768px) {
            content: 'Próximo  \2192' !important;
          }
        }
      }

      a,
      button {
        text-decoration: none;

        color: #2a353c;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  &.pagination-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 1rem 1rem 1rem;

    .align-pagination {
      display: grid;
      grid-template-columns: 1fr auto 1fr;

      width: 100%;
      color: #2a353c;

      p {
        font-size: 0.875rem;
        white-space: nowrap;

        color: #2a353c;
        display: flex;
        align-items: center;
      }
    }
  }
}
