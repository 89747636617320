@import 'variables';

.option-menu-container {
  border-top: 4px solid #2a353c;
  border-radius: 0.5rem !important;
  position: relative;
  overflow: visible !important;

  &.mat-menu-panel {
    min-height: 100% !important;
    border-radius: 4px 0 4px 4px !important;
    bottom: 32px;
    left: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0.5rem;
    top: -1rem;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #2a353c;
  }
}

.benefits-menu {
  margin: 1rem 0 0 1rem !important;
  background: #f6f6f6 !important;

  .mat-drawer-content {
    height: calc(100vh - 104px) !important;
    width: calc(100vw - 21.5rem) !important;
  }

  .mat-drawer {
    border-radius: 0.5rem;
    width: 219px;
    overflow: auto !important;
    box-shadow: none;

    .mat-drawer-inner-container {
      display: block !important;
    }

    .mat-drawer-container {
      margin: 0.625rem;
    }
  }
  .menu-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .menu-title {
      border-radius: 8px 8px 0 0;
      height: 3rem;
      background-color: #e8e8e8;
      color: $gray-biz;
      padding-inline-start: 1rem;

      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }

    mat-accordion {
      .mat-expansion-panel-spacing {
        margin: 0 !important;
      }

      .mat-expansion-panel {
        background: transparent;
        box-shadow: none;

        border-bottom: 1px solid #f6f6f6;

        .mat-expansion-panel-header {
          text-transform: uppercase;
          padding-inline: 1rem;
          font-size: 0.875rem;
          font-weight: 600;

          &.active-link {
            .mat-expansion-panel-header-title {
              color: $primary !important;
            }
          }

          &.mat-expanded {
            &.active-link {
              .mat-expansion-panel-header-title {
                color: $primary !important;
              }
            }
          }
        }

        .mat-expansion-panel-header.mat-expanded {
          height: 3rem;
        }

        .mat-expansion-panel-body {
          padding: 0 !important;
          text-transform: uppercase;
          padding-inline: 1rem;
          font-size: 0.875rem;
          font-weight: 600;

          .mat-expansion-panel-header.mat-expanded {
            height: 3rem;
          }
        }
      }
    }
  }
}

.banking-menu {
  background-color: transparent !important;
  margin: 1rem 0 0 1rem !important;
  background: #f6f6f6 !important;

  .mat-drawer-content {
    height: calc(100vh - 104px) !important;
    width: calc(100vw - 21.5rem) !important;
  }

  .mat-drawer {
    border-radius: 0.5rem;
    width: 12.0625rem;

    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;

      header {
        padding: 1rem;
        background-color: #e8e8e8;
        color: $gray-biz;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }
  }
}
