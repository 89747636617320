.grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.invert {
  filter: invert(100%);

  a {
    color: #185abc !important;

    &:hover {
      color: #4f8be4 !important;
    }
  }

  img {
    filter: invert(100%);
  }

  input {
    color: #185abc !important;
  }

  mat-label {
    color: #185abc !important;
  }
}
