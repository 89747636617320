@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import 'fonts';
// Theme
@import 'main-theme';
@import 'theme-config';

@include mat.all-component-themes($all-in-one-main-theme);

// components
@import 'custom-accordion';
@import 'custom-accessibility';
@import 'custom-button';
@import 'custom-calendar';
@import 'custom-form-field';
@import 'custom-select-input';
@import 'custom-slide-toggle';
@import 'custom-dialog';
@import 'custom-radio';
@import 'custom-expansion';
@import 'custom-xng-breadcrumb';
@import 'custom-color-block';
@import 'custom-pagination';
@import 'custom-menu';
@import 'custom-tabs';
@import 'custom-table';
@import 'custom-checkbox';
@import 'custom-cdn-icons';
@import 'custom-header';
@import 'custom-scroll';
@import 'custom-snackbar';
@import 'custom-stepper';
@import 'custom-datepicker';

* {
  box-sizing: border-box;
}

body {
  width: calc(100vw - (100vw - 100%));
  font-family: $tt-hoves !important;
  margin: 0;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

.mat-drawer-transition .mat-drawer-content {
  transition: none !important;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #e8e8e8;
}

.mat-mdc-header-row {
  z-index: 8 !important;
}
