//////////////////  DeleteExistingLinkComponent(manage-ftp.component)

.delete-existing-link {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 320px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }

  .decision {
    .mat-button-wrapper {
      color: #ffffff;
      font-size: 14px;
    }
  }
}

//////////////////  DeleteConfirmationComponent(manage-ftp.component)
.generic-dialog,
.delete-confirmation-component {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 320px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }
}

////////////////// AddHolidayComponent(manage-holidays)

.custom-dialog-body {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 600px;

    @media (max-width: 960px) {
      height: 500px;
    }
  }
}

.custom-dialog-search-container {
  width: 296px;

  .mat-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-form-field-flex {
    cursor: pointer;
    display: flex;
    height: 48px;
    align-items: center;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0 0.75em;
    margin-top: -0.25em;
    position: relative;
    height: 48px;
  }

  .mat-mdc-dialog-container {
    padding: 0;
    width: 296px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $primary;
  }
}

///////////////////////////////////////// AdvancedSearchComponent(profile-list.component)

.advanced-search-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 296px;

    @media (max-width: 960px) {
      height: 500px;
    }
  }

  .mat-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-form-field-flex {
    cursor: pointer;
    display: flex;
    height: 48px;
    align-items: center;
    width: 15.5rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0 0.75em;
    margin-top: -0.25em;
    width: 248px;
    height: 48px;
    position: relative;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $primary;
  }
}

//////////////////////////// SearchCardConfigurationComponent(search-card-configuration.component)

.search-card-configuration-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 296px;
  }
}

//////////////////////////// RegisterDammingComponent(manage-damming.component)

.custom-dialog-body-manage {
  .mat-mdc-dialog-container {
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 296px;
  }
}

//////////////////////////// dialog advanced-search

.custom-dialog-container {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 664px;
  }
}

//////////////////////////// dialog advanced-search-bearer

.custom-dialog-search-bearer {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 576px;
  }
}

//////////////////////////// dialog loading

.custom-dialog-loading {
  .mat-mdc-dialog-container {
    box-shadow: none;
    padding: 0;
    width: 467px;
    height: 343px;
    background-color: transparent;
    overflow: hidden;
  }
}

.custom-dialog-loading-material {
  .mat-mdc-dialog-container {
    box-shadow: none;
    padding: 0;
    background: transparent;
    overflow: hidden;

    .mdc-dialog__surface {
      background: transparent;
    }
  }
}

.custom-dialog-loading-api {
  .mat-mdc-dialog-container {
    width: 252px;

    .mat-mdc-dialog-surface {
      box-shadow: none;
      padding: 0;
      width: 252px;
      height: 252px;
      background-color: transparent;
      overflow: hidden;
    }
  }
}

.custom-dialog-width {
  .mat-mdc-dialog-container {
    border-radius: 0.5rem;
    padding: 0;
  }
}

.large-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 552px;

    @media (max-width: 960px) {
      width: 320px;
    }
  }
}

///////////////////////////////////////// dialog contract-product

.contract-product-dialog {
  width: 21.875rem;
}

///////////////////////////////////////// dialog add-doubt

.custom-dialog-add-doubt {
  width: 600px;

  .mat-mdc-dialog-container {
    padding: 0;
  }
}

///////////////////////////////////////// AddCardConfigurationComponent

.add-card-configuration-dialog {
  width: 1048px;

  .mat-mdc-dialog-container {
    width: 100%;
    padding: 0;
    background-color: #f6f6f6;
  }
}

///////////////////////////////////////// AddCardConfigurationComponent

.edit-card-configuration-dialog {
  width: 424px;

  .mat-mdc-dialog-container {
    width: 100%;
    padding: 0;
    background-color: #f6f6f6;
  }
}

///////////////////////////////////////// DetailsCardConfigurationComponent

.details-card-configuration-dialog {
  width: 1048px;

  .mat-mdc-dialog-container {
    width: 100%;
    padding: 0;
    background-color: #f6f6f6;
  }
}

//////////////////  RegisterInLotComponent(register-in-lot.component)
.register-in-lot {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 380px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }
}

//////////////////  UploadDialogComponent(upload-dialog.component)
.upload-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 536px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }
}

//////////////////  ErrorDialogComponent
.error-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 318px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }
}

//////////////////  RegisterDialogComponent
.register-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 755px;

    @media (max-width: 960px) {
      width: 500px;
    }
  }
}

///////////////////////////////////////// AddCardConfigurationComponent

.generate-bond-dialog {
  width: 714px;

  .mat-mdc-dialog-container {
    padding: 0;
    background-color: #f6f6f6;
  }
}

//////////////////////////// AdvancedSearchUserComponent

.custom-dialog-advance-search-user {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 775px;
  }
}
//////////////////////////// ConfirmarionBuildComponent

.custom-dialog-confirmarion-build {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 390px;
  }
}

//////////////////  DeleteConfirmationComponent(manage-ftp.component)
.generic-dialog,
.cancel-confirmation-component {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 375px;

    @media (max-width: 960px) {
      width: 256px;
    }
  }
}

//////////////////  genericFunctionsDialog /////////////////

.generic-functions-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 447px;
  }
}

//////////////////  NotificationFilterDialogComponent /////////////////

.notifications-filter-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    width: 588px;
  }
}

////////////////// New/Edit BrancheComponent /////////////////

.branche-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    min-width: 375px;
  }
}
