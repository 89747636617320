$tt-hoves: 'tt-hoves', sans-serif;

@font-face {
  font-family: 'tt-hoves';
  src:
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-light.otf')
      format('opentype'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-light.woff2')
      format('woff2'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-light.woff')
      format('woff'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'tt-hoves';
  src:
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-regular.otf')
      format('opentype'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-regular.woff2')
      format('woff2'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-regular.woff')
      format('woff'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'tt-hoves';
  src:
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-medium.otf')
      format('opentype'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-medium.woff2')
      format('woff2'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-medium.woff')
      format('woff'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'tt-hoves';
  src:
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-demi-bold.otf')
      format('opentype'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-demi-bold.woff2')
      format('woff2'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-demi-bold.woff')
      format('woff'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-demi-bold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'tt-hoves';
  src:
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-bold.otf')
      format('opentype'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-bold.woff2')
      format('woff2'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-bold.woff')
      format('woff'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'tt-hoves';
  src:
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-extra-bold.otf')
      format('opentype'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-extra-bold.woff2')
      format('woff2'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-extra-bold.woff')
      format('woff'),
    url('https://cdn-aiofiles.biz.com.br/site/fonts/tt-hoves-extra-bold.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}
