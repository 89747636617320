@import 'variables';

.custom-stroked-button {
  height: 3rem;
  border: 1px solid $primary !important;
  color: $primary;
  font-weight: 600;
}

.permission__button {
  &:disabled {
    opacity: 0.5;
  }
}

.custom-default-button {
  .mdc-button__label {
    color: #fff;
  }
}

.custom-space-icon {
  .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.custom-space-icon-gap {
  .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
}

.custom-button-menu-item {
  font-size: 0.875rem;

  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.custom__menu__item {
  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

.mdc-icon-button {
  .mdc-icon-button__ripple {
    display: none !important;
  }
}

.disabled__mdc-button__ripple {
  .mdc-button__ripple {
    display: none !important;
  }
}

.disabled__padding {
  &.mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0 !important;
  }
}
