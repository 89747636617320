@import 'variables';

.mat-datepicker-content {
  margin-block-start: 16px;

  .mat-calendar {
    background-color: $gray-normal;
    height: 100% !important;

    .mat-calendar-header {
      .mat-calendar-controls {
        justify-content: space-between;

        :nth-child(1) {
          order: 2;

          .mat-button-wrapper {
            span {
              display: block;
              text-transform: lowercase;

              &::first-letter {
                text-transform: uppercase !important;
              }
            }

            .mat-calendar-arrow {
              display: none;
            }
          }
        }
      }
    }

    .mat-calendar-content {
      .mat-calendar-table {
        .mat-calendar-table-header {
          .mat-calendar-table-header-divider {
            display: none;
          }
        }

        .mat-calendar-body {
          .mat-calendar-body-label {
            visibility: hidden;

            &[colspan='7'] {
              display: none;
            }
          }

          .mat-calendar-body-cell {
            font-size: 0.875rem;

            .mat-calendar-body-cell-content {
              border-radius: 0.25rem !important;
            }
          }

          .highlight-dates
            > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
            color: $primary;
          }

          .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
            > .mat-calendar-body-cell-content:not(
              .mat-calendar-body-selected
            ):not(.mat-calendar-body-comparison-identical),
          .cdk-keyboard-focused
            .mat-calendar-body-active
            > .mat-calendar-body-cell-content:not(
              .mat-calendar-body-selected
            ):not(.mat-calendar-body-comparison-identical),
          .cdk-program-focused
            .mat-calendar-body-active
            > .mat-calendar-body-cell-content:not(
              .mat-calendar-body-selected
            ):not(.mat-calendar-body-comparison-identical) {
            background-color: $primaryTranslucid;
          }
        }
      }
    }
  }
}

mat-datepicker-toggle {
  &.cdk-mouse-focused,
  &.cdk-keyboard-focused,
  &.cdk-touch-focused,
  &.cdk-program-focused {
    background-color: transparent !important;
    background: none !important;
  }

  .mdc-icon-button__ripple {
    display: none !important;
  }
}
