@import 'variables';

.custom-expansion-panel {
  &.mat-expansion-panel-header-title,
  &.mat-expansion-panel-header-description {
    display: block;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.125rem;

    mat-icon {
      color: $primary;
    }

    .customer-title {
      .text-customer {
        margin: 0;
        font-size: 14px;
        color: black;
      }

      .text-customer-color {
        margin: 0;
        font-size: 14px;
        color: $primary;
      }
    }
  }
}

.custom-expansion-panel-unused {
  .mat-expansion-panel-body {
    padding: 0 24px 16px;
    padding-inline-end: 0;
    max-height: 31.25rem;
    overflow-y: auto;
  }

  &.mat-expansion-panel-header-title,
  &.mat-expansion-panel-header-description {
    display: block;
    margin-block-start: 16px;
    margin-inline-end: 16px;
  }

  .title-unused {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    cursor: pointer;

    mat-icon {
      color: #939598;
    }

    .customer-title-unused {
      .text-customer-unused {
        margin: 0;
        padding-block-end: 10px;
        color: #939598;
      }
    }
  }
}

.custom-expansion-panel-filter {
  form {
    .custom-form-field {
      width: 100%;
      height: 10px;
    }
  }

  .mat-expansion-panel-body {
    padding-inline-end: 0px;
  }

  &.mat-expansion-panel-header-title,
  &.mat-expansion-panel-header-description {
    display: block;
    margin-block-start: 5px;
    margin-inline-end: 16px;
  }
}
