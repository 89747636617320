@import 'variables';

.custom-accordion {
  background-color: transparent !important;
  box-shadow: none !important;

  &.sub-menu {
    .mat-expansion-panel-header {
      padding-inline-start: 1.5rem !important;
      font-size: 0.875rem !important;
    }
  }

  .mat-expansion-panel-header {
    padding-inline: 1rem !important;
    font-size: 0.875rem !important;
    height: 2.5rem !important;

    &.mat-expanded {
      height: 2.5rem !important;

      .mat-expansion-panel-header-title {
        font-weight: 600;
      }
    }

    .mat-expansion-panel-header-title {
      color: $gray-biz !important;
      font-weight: 600;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
    font-size: 0.875rem !important;
  }

  .mat-nav-list {
    a {
      font-size: 0.875rem !important;
      height: 2.5rem !important;

      &.active-route {
        color: $primary;

        mat-icon {
          display: inline;
        }

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .mat-list-item-content {
        padding-inline-start: 1.5rem;
      }

      mat-icon {
        height: 14px;
        width: 14px;
        font-size: 14px;
        vertical-align: text-top;
        margin-inline-end: 8px;
        display: none;
      }
    }
  }
}
