@import 'variables';

.custom__tab {
  display: flex;

  &.tab__benefits {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 1px;
    }

    .tab__label {
      background: transparent !important;
      margin: 0;

      a {
        color: #62676b;
      }

      &:last-child {
        flex: 1 1 0% !important;
        display: flex;
        justify-content: flex-start;
      }

      &.active {
        background: $primary-opacity !important;
        border-bottom: solid 3px $primary !important;

        a {
          border-radius: 6px 6px 0 0;
          color: $primary !important;
        }
      }
    }
  }

  .tab__label {
    flex-grow: 0 !important;
    border-radius: 0.4rem 0.4rem 0 0;
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    margin-inline-end: 0.5rem;
    cursor: pointer;
    border-bottom: solid 2px #e8e8e8 !important;

    &.active {
      background: #ffffff;
      opacity: 1;
      border-bottom: solid 3px #36c38c !important;
    }

    a {
      padding-block: 12px;
      font-size: 16px;
      color: $gray-biz;
      font-weight: 600;
      opacity: 0.6;
      padding-inline: 24px;
      text-align: center;

      &.active {
        background: #ffffff;
        opacity: 1 !important;
        border-bottom: solid 3px #36c38c !important;
      }
    }
  }
}
