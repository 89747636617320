@import 'variables';

.mat-mdc-form-field-type-mat-select {
  position: relative;

  .mat-mdc-form-field-outline-end {
    &::after {
      content: '';
      border-left: 1px solid #c7c7c7;
      position: absolute;
      top: 0.5rem;
      right: 3rem;
      height: 2rem;
    }
  }

  .mat-mdc-select-value {
    display: table-cell;
    width: 100%;
    padding-inline-end: 1.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mat-mdc-select-arrow {
    width: 0.375rem;
    height: 0.375rem;
    border-top: unset;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
    border-left: unset;
    margin: 0;
    margin-right: 10px;
    color: $primary;
    transform: rotate(45deg);

    svg {
      display: none;
    }
  }
}

.custom_base_application {
  position: absolute;
  z-index: 0;
  right: -4px;
}

.option-search.mdc-list-item--disabled {
  cursor: pointer !important;
  pointer-events: auto !important;

  .mdc-list-item__primary-text {
    opacity: 1 !important;
  }
}

.mat-mdc-select-panel {
  padding: 0 !important;
}

.form-field-adjustment {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      transform: translateY(-1.545em) scale(0.75) !important;
    }

    .mat-mdc-form-field-infix {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }

  .mat-mdc-text-field-wrapper {
    height: 33px;

    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 18px;
    }
  }
}

.custom-form-select-fill {
  .mat-mdc-text-field-wrapper {
    background: #e8e8e8 !important;
    border-radius: 4px !important;
  }

  .mdc-notched-outline__leading {
    border: none !important;
  }

  .mdc-notched-outline__trailing {
    border: none !important;
  }

  .mdc-notched-outline__notch {
    border: none !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 36px !important;
  }
}

.select__category {
  .mat-mdc-select-arrow-wrapper {
    display: none !important;
  }
}

.custom-company.mat-mdc-form-field-type-mat-select {
  .mat-mdc-select-arrow {
    width: 0.575rem;
    height: 0.575rem;
    border-right: 1px solid $secondary;
    border-bottom: 1px solid $secondary;
    color: $secondary;
  }
}

.custom-simple-select {
  display: flex !important;
  height: 36px;
  border: 1.5px solid $gray-b3;
  border-radius: 6px;
  padding-inline: 0.75rem;
  font-size: 1rem;
  color: #2a353c;
  width: 11rem;
  &.mat-mdc-select-disabled {
    border-color: $gray-b4;
    background-color: $gray-b4;
  }

  .mat-mdc-select-arrow {
    width: 0.375rem;
    height: 0.375rem;
    border-top: unset;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
    border-left: unset;
    margin: 0;
    margin-right: 10px;
    color: $primary;
    transform: rotate(45deg);

    svg {
      display: none;
    }
  }
}
