::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track-piece {
  background: #e8e8e8;
}

::-webkit-scrollbar-thumb {
  background: rgba(49, 57, 63, 0.5);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-corner {
  background: #e8e8e8;
}
