$primary: var(--primary);
$secondary: var(--secondary);
$primary-opacity: var(--primaryOpacity);
$secondary-opacity: var(--secondaryOpacity);
$tertiary-opacity: var(--tertiaryOpacity);
$temporary-background: var(--temporaryBackground);

// GRAY
$gray-biz: #2a353c;
$gray-b1: #62676b;
$gray-b2: #939598;
$gray-b3: #c7c7c7;
$gray-b4: #e8e8e8;
$gray-normal: #f6f6f6;
$gray-100: #f5f5f5;
$gray-150: #f7f7f7;
$gray-200: #a8a8a8;

// WARN
$warn: #b30021;
$warn-600: #dc2626;
$warn-500: #ef4444;

// WARNING
$warning-600: #ea580c;
$warning-500: #f97316;

// SUCCESS
$success: #36c38c;
$success-600: #16a34a;
$success-500: #22c55e;

// INFO
$info: #faa42c;
$info-600: #2563eb;
$info-500: #3b82f6;

// COLORS BI
$orange-dark: #d43f11;
$orange-biz: #f05929;
$orange-b1: #f6892f;
$orange-b2: #faa42c;
$orange-b3: #fac870;
$orange-b4: #eae1e1;

// OTHERS/CONFIGS
$textColor: var(--textColor);
$primaryTranslucid: #d43e110d;
$primaryContrast: #ffffff;
$gray-bizContrast: #ffffff;
$warnContrast: #ffffff;

$white: #ffffff;
$black: #ffffff;
