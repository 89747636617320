@import 'variables';

.xng-breadcrumb-root {
  padding: 1.5rem 1.5rem 0 1.5rem;

  .xng-breadcrumb-item {
    color: #2a353c;
    font-size: 1rem;
  }

  .xng-breadcrumb-separator {
    margin-right: 0.125rem;
    margin-left: 0.3125rem;

    mat-icon {
      font-size: 0.9375rem;
      height: 0.9375rem;
      width: 1.125rem;
      color: $primary !important;
    }
  }

  .xng-breadcrumb-link {
    font-weight: 400;
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }

  .xng-breadcrumb-trail {
    font-weight: 600;
    color: #2a353c;
  }
}

.seput {
  .xng-breadcrumb-root {
    background-color: #ffffff;
  }
}
