.custom-snackbar-panel {
  display: flex !important;
  justify-content: center !important;
  width: 100vw !important;
  max-width: unset !important;
  min-width: unset !important;
  padding: 0 !important;
  margin: 5.5rem 0 0 5.5rem !important;

  :first-child {
    flex: 100%;

    :first-child {
      flex: 100%;

      app-custom-snackbar {
        flex: 100%;
      }
    }
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  max-width: 100% !important;
  border-radius: 11px !important;
  color: transparent !important;
  background: transparent !important;
  box-shadow:
    0px 0px 0px 0px transparent,
    0px 0px 0px 0px transparent,
    0px 0px 00px 0px transparent !important;
}
