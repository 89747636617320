.custom__header {
  margin-block-end: 1.5rem;

  h1 {
    font-size: 3.5rem;
    font-weight: 600;
    color: #2a353c;
    margin: 0;
  }
}
