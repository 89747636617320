@import 'variables';

.custom-form-field {
  &.large-field .mat-mdc-text-field-wrapper {
    height: 56px;

    &.mdc-text-field--disabled {
      background: #ededed !important;

      input {
        margin-top: 6px;
      }

      .mdc-notched-outline__notch {
        border-top: 1px solid #c7c7c7 !important;

        .mdc-floating-label {
          margin-block-start: 12px;
        }
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    width: 100%;
    height: 48px;
  }

  .mdc-notched-outline__notch {
    border-right: none !important;
    border-left: none !important;
    border-color: #c7c7c7 !important;
  }

  .mdc-notched-outline__leading {
    border-color: #c7c7c7 !important;
  }

  .mdc-notched-outline__trailing {
    border-color: #c7c7c7 !important;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
}

.change-document-type {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    display: flex;
  }

  .mat-mdc-floating-label {
    padding-inline-start: 88px;

    &.mdc-floating-label--float-above {
      padding: 0 !important;
    }
  }
}

.form-field-disable-fill {
  .mdc-text-field--filled.mdc-text-field--disabled {
    background: #e8e8e8 !important;
    border-radius: 4px !important;
    padding-right: 12px !important;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
    border: none;
  }
}

.custom-form-field-fill {
  .mdc-text-field--filled.mdc-text-field--disabled {
    background: #e8e8e8 !important;
    border-radius: 4px !important;
    padding-right: 12px !important;
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 8px;
  }

  .mdc-text-field--filled.mdc-text-field .mdc-line-ripple::before {
    border: none;
  }
  .mdc-text-field--filled.mdc-text-field .mdc-line-ripple::after {
    border: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #2a353c !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

input[type='password']::-webkit-calendar-picker-indicator,
input[type='password']::-webkit-clear-button {
  display: none;
}

input[type='password']::-ms-reveal {
  display: none;
}

.permission__input:disabled {
  cursor: not-allowed;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield; /* for Firefox */
}

.mat-mdc-form-field-error {
  font-size: 12px;
}

.custom-textearea-input {
  .mdc-notched-outline__notch {
    border-right: none;
    border-color: #c7c7c7 !important;
  }

  .mdc-notched-outline__leading {
    border-color: #c7c7c7 !important;
  }

  .mdc-notched-outline__trailing {
    border-color: #c7c7c7 !important;
  }
}

.custom-simple-input {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      height: 24px;
    }

    &.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding: 0 !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-icon {
  height: auto !important;
}

.custom__text__area__input {
  .mdc-text-field {
    padding: 0 !important;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
    border: none !important;
  }
}
