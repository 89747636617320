@import 'variables';

.custom-radio-input {
  mat-radio-group {
    display: grid;

    mat-radio-button {
      padding: 1rem;

      &.mat-radio-checked .mat-radio-label-content {
        color: $primary;
      }
    }
  }
}

.radio-input-label {
  .mat-radio-label {
    color: #c7c7c7;
  }
}

.radio-input {
  mat-radio-group {
    display: grid;

    mat-radio-button {
      border: 1px solid #c7c7c7;
      padding: 16px;
      border-radius: 10px 10px 0px 0px;

      b,
      span.separator {
        font-weight: 100;
        color: #c7c7c7;
        padding-inline-start: 0.4375rem;
      }

      span.collaborator {
        padding-inline-start: 0.8125rem;
      }
    }
  }
}

.advanced-radio-group {
  &.custom-color
    .mat-mdc-radio-group
    .mat-mdc-radio-button.mat-mdc-radio-checked
    .mdc-form-field {
    color: $secondary;
  }

  .mat-mdc-radio-group {
    display: grid;

    .mat-mdc-radio-button {
      border: 1px solid #c7c7c7;
      padding: 8px;

      .mdc-form-field {
        color: #c7c7c7;
      }

      &.mat-mdc-radio-checked {
        .mdc-form-field {
          color: $primary;

          .mat-mdc-input-element {
            background-color: transparent;

            &:focus-visible {
              outline: none !important;
            }
          }
        }
      }
    }
  }
}

.advanced-radio-group
  .mat-mdc-radio-group
  .mat-mdc-radio-button
  .mdc-form-field
  .mat-mdc-input-element {
  background-color: transparent !important;
}
