@mixin custom-table($bg-header) {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .mat-mdc-table {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: none;

    .mat-mdc-header-row,
    .mat-mdc-row {
      gap: 1rem;
    }

    .mat-mdc-header-row {
      background-color: $bg-header;
      border-bottom-color: transparent;
      border-radius: 0.5rem 0.5rem 0 0;

      .mat-mdc-header-cell {
        text-transform: uppercase;
        font-weight: 600;
        color: $primary;
        font-size: 0.875rem;
        border-bottom-color: transparent;
        z-index: 7 !important;
      }
    }

    .mat-mdc-row {
      border-bottom: 1px solid #f7f7f7;
      background-color: #fff;
      .mat-mdc-cell {
        border-bottom: none;
      }
    }
  }
}

.mat-sort-header-container {
  border-bottom: none !important;
}

.mat-sort-header-arrow {
  color: $primary;
  opacity: 1 !important;
  transform: translateY(0) !important;
}
